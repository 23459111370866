import React from 'react'
import Section from '../components/section'
import styled from 'styled-components';
import BottomBullets from '../components/bottom-bullets';
import Integrations from '../components/integrations'

const content = [
    {
        id: 0,
        type: 'intro',
        name: 'productivity-reinvented',
        legend: 'Streamline Your Tasks Across All Project Tools',
        title: 'Swipeable, Glanceable and Dealwithable',
        background: '/images/bg.jpg',
        text: 'Schedulist integrates seamlessly with your existing project management tools, allowing you to focus on what matters now and postpone the rest.',
        class: 'flex flex-col items-center justify-start font-sans min-h-96 bg-gray-50 lg:pt-10 lg:pb-20 lg:bg-hero dark:bg-black bg-cover bg-center',
        animate: 'fadeIn',
    },
    {
        id: 1,
        name: 'seamless-integrations',
        title: 'Seamless Integrations',
        asset: '/images/app-screens/02',
        darkAsset: '/images/app-screens/02_dark',
        text: 'Manage tasks across all your project tools in one unified platform.',
        class: 'lg:items-center lg:flex lg:flex-row lg:justify-center',
        align: 'left',
        animate: 'fadeInRight',
    },
    {
        id: 2,
        name: 'intuitive-task-management',
        title: 'Intuitive Task Management',
        asset: '/images/app-screens/01',
        darkAsset: '/images/app-screens/01_dark',
        text: 'Add, complete, and schedule tasks effortlessly with simple swipe actions.',
        class: 'lg:items-center lg:flex lg:flex-row-reverse lg:justify-center',
        align: 'right',
        animate: 'fadeInLeft',
    },
    {
        id: 3,
        name: 'prioritize-with-ease',
        title: 'Prioritize with Ease',
        asset: '/images/app-screens/03',
        darkAsset: '/images/app-screens/03_dark',
        text: 'View only the tasks you need to tackle now, effortlessly postponing others for later.',
        class: 'lg:items-center lg:flex lg:flex-row lg:justify-center',
        align: 'left',
        animate: 'fadeInRight',
    },
    {
        id: 4,
        name: 'cross-platform-availability',
        title: 'Cross-Platform Availability',
        asset: '/images/app-screens/04',
        darkAsset: '/images/app-screens/04_dark',
        text: 'Access Schedulist on iOS, Android, Mac, and the web for ultimate flexibility.',
        class: 'lg:items-center lg:flex lg:flex-row-reverse lg:justify-center',
        align: 'right',
        animate: 'fadeInLeft',
    },
    {
        id: 5,
        name: 'take-control-today',
        title: 'Take Control Today',
        asset: '/images/app-screens/05',
        darkAsset: '/images/app-screens/05_dark',
        text: 'Experience a more organized and productive workflow. Download Schedulist today and take control of your tasks like never before.',
        class: 'lg:items-center lg:flex lg:flex-row lg:justify-center',
        align: 'left',
        animate: 'fadeInRight',
    },
];

const Home = styled(({className}) => {
	return (
        <div className='box-border'>
            <div className='flex flex-col'>
                <Section content={content} />

                <BottomBullets />
                <Integrations />
            </div>
        </div>
	);
})`
`;

export default Home
